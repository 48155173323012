<template>
  <div class="holder">
    <h1>innerRadius：圆弧的内半径（Number） &nbsp; 默认值：60</h1>
    <h1>outerRadius：圆弧的外半径（Number） &nbsp; 默认值：80</h1>
    <h1>arcMin：起始角度（Number） &nbsp; 默认值：(-Math.PI * 2) / 3</h1>
    <h1>arcMax：终止角度（Number） &nbsp; 默认值：(Math.PI * 2) / 3</h1>
    <h1>valueDomain：圆弧对应的取值范围（Array） &nbsp; 默认值：[0,100]</h1>
    <h1>arcBgColors：圆弧的颜色（String） &nbsp; 默认值："#444851"</h1>
    <h1>arcVisible：是否显示圆弧（Boolean） &nbsp; 默认值：true</h1>
    <h1>
      marginTop、marginBottom、marginRight、marginLeft：这四个参数控制偏移量（Number）
      &nbsp; 默认值：0
    </h1>
    <h1>
      tickInfo：刻度参数（Object） &nbsp;
      <div class="sub">
        <p>show：是否显示刻度（Boolean） &nbsp; 默认值：true</p>
        <p>count：显示刻度的数量（Number） &nbsp; 默认值：5</p>
        <p>color：刻度线颜色（String） &nbsp; 默认值："#444851"</p>
        <p>width：刻度线宽度（Number） &nbsp; 默认值：2</p>
        <p>len：刻度线长度（Number） &nbsp; 默认值：4</p>
      </div>
    </h1>
    <h1>
      pointInfo：指针参数（Object） &nbsp;
      <div class="sub">
        <p>color：指针颜色（String） &nbsp; 默认值："#999"</p>
        <p>width：指针宽度（Number） &nbsp; 默认值：2</p>
        <p>len：指针长度（Number） &nbsp; 默认值：20</p>
      </div>
    </h1>
    <h2>
      展示仪表盘
      <span @click="playIn1('gaugeChart')">入场</span>
      <span @click="playOut('gaugeChart')">出场</span>
    </h2>
    <gauge-chart ref="gaugeChart" id="gaugeChart" customClass="c_bar2 c_bar1" />
  </div>
</template>
<script>
import * as d3 from "d3";
import gaugeChart from "@/components/gaugeChart/index.vue";
export default {
  data: function () {
    return {
      data: {
        value: 18,
        suffix: "%",
        color: "#999",
      },
    };
  },
  components: { gaugeChart },
  methods: {
    playIn1(refName) {
      this.$refs[refName].ratioBarChart(this.data, {
        x: (d) => d.label,
        width: 300,
        height: 180,
        delay: 500,
        duration: 1000,
        marginBottom: 30,

        innerRadius: 60, //圆弧的内半径
        outerRadius: 80, //圆弧的外半径
        arcMin: (-Math.PI * 2) / 3, //
        arcMax: (Math.PI * 2) / 3, //
        valueDomain: [10, 20], //
        arcBgColors: "brown",
        // arcVisible: false, //
        // tickInfo: {
        //   show: true,
        //   count: 10,
        //   color: "red",
        //   width: 2,
        //   len: 5,
        // },
        // pointInfo: {
        //   color: "#FFAB91",
        //   width: 2,
        //   len: 30,
        // },
      });
    },

    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h2 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;

  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h1 {
  font-size: 18px;
  color: #9c27b0;
  margin-bottom: 10px;
  .sub {
    p {
      text-indent: 30px;
      color: #953e23;
    }
  }
}

.c_bar1 {
  background: #ffd918;
  background: -webkit-linear-gradient(bottom right, #ffd918, #e8882d);
  background: -moz-linear-gradient(bottom right, #ffd918, #e8882d);
  background: linear-gradient(to top left, #ffd918, #e8882d);
  font-size: 14px;
  min-height: 100px;
  display: inline-block;
  margin-bottom: 30px;
}
.box {
  margin: 10% auto;
  width: 600px;
  height: 400px;
  background-color: #ddd;
}

.title {
  font-size: 20px;
}

.label {
  font-size: 13px;
}

.polygon {
  fill-opacity: 0.8;
}
.c_bar2 {
  /deep/ .text {
    font-family: "harmonyMidium";
    font-size: 18px;
  }
}
</style>
