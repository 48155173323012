<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      yScale: null,
      yDomain: [],
      ease: "easeBack",
      finalPoints: [],
      initialPoints: [],
    };
  },
  methods: {
    async playOut() {
      const that = this;
      this.svg.transition().duration(1000).style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    ratioBarChart(
      data,
      {
        x = (d, i) => i, // given d in data, returns the (ordinal) x-value
        // y = (d) => d, // given d in data, returns the (quantitative) y-value
        // z = () => 1,
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        // xDomain, // an array of (ordinal) x-values
        // xRange = [marginLeft, width - marginRight], // [left, right]
        // yDomain, // [ymin, ymax]
        // yRange = [height - marginBottom, marginTop], // [bottom, top]
        // zDomain, // array of z-values
        // zPadding = 0.05,
        // xPadding = 0.1, // 柱子的比例
        marginTop = 0, // the top margin, in pixels
        marginRight = 0, // the right margin, in pixels
        marginBottom = 0, // the bottom margin, in pixels
        marginLeft = 0, // the left margin, in pixels

        duration = 400, //动画持续时长
        delay = 100, //元素之间间隔时长
        tickInfo = {},
        tickNumber = tickInfo.count || 5,
        tickColor = tickInfo.color || "#444851",
        tickWidth = tickInfo.width || 2,
        tickLen = tickInfo.len || 4,
        tickShow = true,
        innerRadius = 60, //圆弧的内半径
        outerRadius = 80, //圆弧的外半径
        arcMin = (-Math.PI * 2) / 3, //
        arcMax = (Math.PI * 2) / 3, //
        valueDomain = [0, 100], //
        arcBgColors = "#444851",
        pointInfo = {},
        pointColor = pointInfo.color || "#999",
        pointWidth = pointInfo.width || 2,
        pointLen = pointInfo.len || 20,
        arcVisible = true,
      } = {}
    ) {
      const that = this;
      this.duration = duration;
      this.delay = delay;
      duration = arcVisible ? duration / 2 : duration;
      tickShow =
        Object.prototype.toString.call(tickInfo.show) === "[object Undefined]"
          ? true
          : tickInfo.show;
      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //角度计算
      const angleScale = d3.scaleLinear(valueDomain, [arcMin, arcMax]); //将value转化为角度
      const valueScale = d3.scaleLinear([arcMin, arcMax], valueDomain); //将角度转化为value
      const suffix = !data.suffix ? "" : data.suffix; //数据单位

      const drawArc = function (deg) {
        return d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .startAngle(arcMin)
          .endAngle(deg);
      };

      const arcPath = svg
        .append("g")
        .attr(
          "transform",
          "translate(" +
            (width / 2 + marginRight + marginLeft) +
            "," +
            (height / 2 + marginBottom + marginTop) +
            ")"
        )
        .attr("class", "arcPath");

      //画圆环
      arcPath
        .append("path")
        .style("fill", arcBgColors)
        .transition()
        .duration(duration)
        .attrTween("d", function (d) {
          var i = d3.interpolate(arcMin, arcMax);
          return function (t) {
            let deg = i(t);
            return drawArc(deg)();
          };
        });
      if (arcVisible) {
        setTimeout(() => {
          arcPath
            .append("path")
            .style("fill", pointColor)
            .transition()
            .duration(duration)
            .attrTween("d", function (d) {
              var i = d3.interpolate(arcMin, angleScale(data.value));
              return function (t) {
                let deg = i(t);
                return drawArc(deg)();
              };
            });
        }, duration);
      }

      //画刻度
      const tickValue = (valueDomain[1] - valueDomain[0]) / tickNumber;
      const tickArr = [];
      for (let i = 0; i <= tickNumber; i++) {
        if (i == 0) {
          tickArr[i] = valueDomain[0];
        } else {
          tickArr[i] = tickArr[i - 1] + tickValue;
        }
      }
      const tickGroup = svg
        .append("g")
        .attr(
          "transform",
          "translate(" +
            (width / 2 + marginRight + marginLeft) +
            "," +
            (height / 2 + marginBottom + marginTop) +
            ")"
        )
        .attr("class", "tickGroup");
      const tick = tickGroup
        .selectAll("g")
        .data(tickArr)
        .join("g")
        .attr("class", "tick")
        .attr("opacity", 0);
      //是否显示刻度
      if (tickShow) {
        tick
          .append("line")
          .attr("stroke", tickColor)
          .attr("stroke-width", tickWidth)
          .attr("x1", (d, i) => {
            return computedAxis(angleScale(d), outerRadius + 2).x;
          })
          .attr("y1", (d, i) => {
            return computedAxis(angleScale(d), outerRadius + 2).y;
          })
          // .attr("style", "stroke:rgb(99,99,99);stroke-width:2")
          .attr("x2", (d, i) => {
            return computedAxis(angleScale(d), outerRadius + 2 + tickLen).x;
          })
          .attr("y2", (d, i) => {
            return computedAxis(angleScale(d), outerRadius + 2 + tickLen).y;
          });
        tick
          .append("text")
          .attr("fill", tickColor)
          .attr("class", "tick_text")
          .attr("x", (d, i) => {
            let offsetX = angleScale(d) < 0 ? -5 : angleScale(d) > 0 ? 5 : 0;
            return (
              computedAxis(angleScale(d), outerRadius + 2 + tickLen).x + offsetX
            );
          })
          .attr("y", (d, i) => {
            let offsetY = angleScale(d) === 0 ? -4 : 2;
            return (
              computedAxis(angleScale(d), outerRadius + 2 + tickLen).y + offsetY
            );
          })
          .text((d, i) => {
            return d;
          })
          .attr("text-anchor", (d, i) => {
            if (angleScale(d) < 0) {
              return "end";
            } else if (angleScale(d) == 0) {
              return "middle";
            } else if (angleScale(d) > 0) {
              return "start";
            }
          });
        tick
          .transition()
          .delay((d, i) => {
            let s = duration / 6;
            return s * i;
          })
          .duration(duration)
          .attr("opacity", 1);
      }

      // console.log(tickArr, tickValue);

      //画指针
      const pointer = svg
        .append("g")
        .attr(
          "transform",
          "translate(" +
            (width / 2 + marginRight + marginLeft) +
            "," +
            (height / 2 + marginBottom + marginTop) +
            ")"
        )
        .attr("class", "pointer");

      setTimeout(() => {
        pointer
          .append("circle")
          .attr("fill", pointColor)
          .attr("cx", 0)
          .attr("cy", 0)
          // .attr("r", 0)
          // .transition()
          // .duration(duration)
          .attr("r", 5);

        const pointerLine = pointer
          .append("line")
          .attr("x1", 0)
          .attr("y1", 0)
          .attr("style", "stroke:" + pointColor + ";stroke-width:" + pointWidth)
          .attr("x2", computedAxis(valueDomain[0], pointLen).x)
          .attr("y2", computedAxis(valueDomain[0], pointLen).y);
        pointerLine
          .transition()
          .duration(duration)
          .tween("attr.line", function (d) {
            let i = d3.interpolate(arcMin, angleScale(data.value));
            return function (t) {
              let deg = i(t);
              let x = computedAxis(deg, pointLen).x;
              let y = computedAxis(deg, pointLen).y;
              d3.select(this).attr("x2", x).attr("y2", y);
            };
          });
      }, duration);

      //根据r计算角度对应在圆的坐标
      function computedAxis(deg, r) {
        //deg是角度 r为半径
        const lineEndX = r * Math.sin(deg);
        const lineEndY = r * Math.cos(deg) * -1;
        return { x: lineEndX, y: lineEndY };
      }

      //画文字
      const textGroup = svg
        .append("g")
        .attr(
          "transform",
          "translate(" +
            (width / 2 + marginRight + marginLeft) +
            "," +
            (height / 2 + marginBottom + marginTop) +
            ")"
        )
        .attr("class", "textGroup");

      const textTran = textGroup
        .append("text")
        .attr("fill", !data.color ? "#444851" : data.color)
        .attr("x", 0)
        .attr("y", 20)
        .attr("text-anchor", "middle")
        .attr("class", "text")
        .text("")
        .attr("dy", "0.5em")
        .attr("font-size", 16);

      setTimeout(() => {
        textTran
          .transition()
          .duration(duration)
          .attrTween("text", function (d) {
            let i = d3.interpolate(valueDomain[0], data.value);
            return function (t) {
              let text = i(t);
              d3.select(this).text(text.toFixed(2) + suffix);
            };
          });
      }, duration);

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
